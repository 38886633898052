<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="heading_alignment allCardList">
        <iq-card style="padding-left:20px; padding-right:20px;">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{cvCardTitle}}
              <span class="btn_in_header">
                <span class="pull-right" v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'">
                  <b-button class="mr-2" variant="primary" @click="gotoSubscriptionsList()">Subscriptions</b-button>
                  <b-button variant="primary" @click="gotoUserList()">Users</b-button>
                </span>
              </span>
            </h4>
          </template>
          <template>
            <div class="row mb-3" >
              <div class="col-12 col-xs-6 col-sm-4 col-md-3 col-lg-3">
                <div class="card text-white bg-danger mb-2" >
                  <div class="card-body">
                    <h4 class="card-title text-white" style="text-align:center">
                      <div>
                        Status: {{whereFilter.pay_status_code}}
                      </div>
                      <h6 class="text-white" >Count: {{countSuccess}}</h6>
                    </h4>
                  </div>
                </div>
              </div>

              <div class="col-12 col-xs-6 col-sm-4 col-md-3 col-lg-3">
                <div class="card text-white bg-success iq-mb-2" >
                  <div class="card-body">
                    <h4 class="card-title text-white" style="text-align:center">
                      <div>Revenue</div>
                      <h6 class="text-white">Rs. {{revenue}}</h6>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template>
            <b-row  >
              <b-col class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
                <!-- Frontend search -->
                <!-- <b-form-input class="search_box"
                  v-model="whereFilter.search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input> -->
                <!-- Frontend Search -->

                  <!--Backend Search  -->
                  <input class="form-control"
                  v-model="whereFilter.search_param"
                  type="search"
                  @input="serachFromBackend"
                  :placeholder="cvSearchText" /><!--Backend Search  -->
              </b-col>

              <!-- Subscription plan name filter -->
              <b-col class="col-12 col-sm-6 col-md-4 col-lg-2 mb-2">
                <select v-model="whereFilter.planId" class="form-control" @change="setSubscriptionNameAndStatusFilter">
                  <option value="ALL">ALL</option>
                  <option v-for="(plan, index) of planIdList" :key="(index+1)" :value="plan.subs_plan_id" :title="plan.subs_plan_id">
                    {{ plan.subs_plan_name}}
                  </option>
                </select>
              </b-col><!-- Subscription plan name filter -->

              <!-- Transaction Status filter -->
              <b-col class="col-12 col-sm-6 col-md-4 col-lg-2 mb-2">
                <select v-model="whereFilter.pay_status_code" class="form-control" @change="setSubscriptionNameAndStatusFilter">
                  <option value="ALL">ALL</option>
                  <option v-for="(status, index) of transactionStatusList" :key="(index+1)" :value="status.pay_status_code">
                    {{ status.pay_status_code}}
                  </option>
                </select>
              </b-col><!-- Transaction Status filter -->

              <!-- Revenue source filter -->
              <b-col class="col-12 col-sm-6 col-md-4 col-lg-2 mb-2">
                <select v-model="whereFilter.revenue_src" class="form-control" @change="setSubscriptionNameAndStatusFilter">
                  <option value="ALL">ALL</option>
                  <option v-for="(src, index) of revenueSrcList" :key="(index+1)" :value="src">
                    {{src}}
                  </option>
                </select>
              </b-col><!-- Revenue source filter -->

              <b-col class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
                <div class="dateRange" style="width: 100%;">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="getPaymentTransactionDateWise()" ></date-range-picker>
                </div>
              </b-col>

              <b-col class="col-12 col-sm-6 col-md-4 col-lg-12 mb-2">
                <!--
                <div v-if="payTransactionsObjList && payTransactionsObjList.length" class="float-right">
                  <VueJsonToCsv :json-data="payTransactionsObjList" csv-title="PaymentTransactionList" >
                    <b-btn variant="primary" class="Download_button mr-2"> Csv
                    </b-btn>
                  </VueJsonToCsv>
                  <b-btn variant="primary" class="Download_button mr-2" @click="showAddSubscriberModal">Add Subscriber</b-btn>
                </div>
                -->

                <!-- Backend csv download -->
                <b-btn variant="primary" class="Download_button" @click="backendCsvDownload">Download</b-btn><!-- Backend csv download -->
              </b-col>
            </b-row>
          </template>

          <template>
            <b-row class="mb-3">
              <!-- <b-col md="8">
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Excel</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Csv</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Coustomize</b-button>
              </b-col> -->
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="payTransactionsObjList && payTransactionsObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="payTransactionsObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(user_id)="data">
                    <span class="pointer" @click="openUserProfile(data.item.user_id)">{{ data.item.user_name }}</span><br>
                    <span><small>
                      Id:&nbsp;{{ data.item.user_id }}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.user_id)"></i><br>
                      Email:&nbsp;{{ data.item.user_email }}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.user_email)"></i><br>
                      Mobile:&nbsp;{{ data.item.user_mobile }}<br><br>
                      Class&nbsp;{{ data.item.class_name }}<br>
                      {{ data.item.org_name }}<br>
                      {{data.item.org_city ? data.item.org_city : '' }}
                      {{data.item.org_state ? ', ' + data.item.org_state : '' }}
                      {{data.item.org_country ? ', ' + data.item.org_country : '' }}<br>
                    </small></span>
                    <b-button variant="mr-1 mb-1" size="sm" @click="openInvoice(data.item.pay_invoice_url)" title="Invoice">
                      <i class="font-size-18 fa fa-file m-0"></i>
                    </b-button>
                    <b-button variant="mr-1 mb-1" size="sm" @click="payTransactionsEdit('MODAL', data.item)" v-if="!data.item.editable">
                      <i class="font-size-18 ri-ball-pen-fill m-0"></i>
                    </b-button>
                    <b-button variant="mr-1 mb-1" @click="showPayTransactionsDeleteDialog(data.item)" size="sm">
                      <i class="font-size-18 ri-delete-bin-line m-0"></i>
                    </b-button>
                  </template>
                  <template v-slot:cell(pay_amount)="data">
                    <p> <span v-html="data.item.pay_amount" class="threeLineOnly vhtmlTag vueHtmlParent"></span>
                    </p>
                  </template>
                  <template v-slot:cell(pay_params)="data">
                    <p v-html="data.item.pay_params" class="threeLineOnly vhtmlTag vueHtmlParent">
                    </p>
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="doCopy(data.item.pay_params)" v-if="!data.item.editable">
                      <i class="fa-solid fa-copy"></i>
                    </b-button>
                  </template>
                   <template v-slot:cell(Amount)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="doCopy(data.item.pay_params)" v-if="!data.item.editable">
                      <i class="fa-solid fa-copy"></i>
                    </b-button>
                  </template>
                  <template v-slot:cell(pay_order_id)="data">
                    <p v-html="data.item.pay_order_id" style="word-wrap: break-word">
                    </p>
                  </template>
                  <template v-slot:cell(created_on)="data">
                    {{data.item.created_on|dateFormatDDMMYYYY}}
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelPayTransactionsEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl">
      <PayTransactionsEdit :propOpenedInModal="true" :propPayTransactionsObj="payTransactionsEditObj" @emitClosePayTransactionsEditModal="closePayTransactionsEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="payTransactionsEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closePayTransactionsEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelPayTransactionsDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>
        {{cvDelMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelPayTransactionsDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="payTransactionsDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<style scoped>
.dateRange {
  float: right;
}
</style>

<script>
import { PayTransactionss } from "../../../FackApi/api/PayTransactions"
import PayTransactionsEdit from "./PayTransactionsEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
import { analyticsPayTransactions } from "../../../FackApi/api/analytics/analytics_payTransactions"
import Utility from "../../../Utils/utility"
import { SubscriptionPlans } from "../../../FackApi/api/Subscription/subscriptionPlan"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "PayTransactionsList",
  components: {
    PayTransactionsEdit
  },
  data () {
    return {
      apiName: "payTransactions_list",
      cvCardTitle: "Payments",
      JsonData: [],
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Payment Transactions",
      cvAddModalHeader: "Fill Details and pay",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Pay Transactions",
      showModelPayTransactionsAdd: false,
      showModelPayTransactionsEdit: false,
      showModelPayTransactionsDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "User Details", key: "user_id", class: "text-left align-text-top w-300px", sortable: true },
        { label: "Subs Plan Id", key: "pay_subs_id", class: "text-left align-text-top w-300px", sortable: true },
        { label: "Amount (INR)", key: "pay_amount", class: "text-left align-text-top", sortable: true },
        { label: "Amount (USD)", key: "pay_amount_usd", class: "text-left align-text-top", sortable: true },
        { label: "Trans. Id", key: "pay_tid", class: "text-left align-text-top w-200px", sortable: true },
        { label: "Order id", key: "pay_order_id", class: "text-left align-text-top w-200px", sortable: true },
        { label: "Status Code", key: "pay_status_code", class: "text-left align-text-top w-200px", sortable: true },
        { label: "Status Desc", key: "pay_status_desc", class: "text-left align-text-top w-200px", sortable: true },
        { label: "Payment Source", key: "pay_source", class: "text-left align-text-top", sortable: true },
        { label: "Revenue Source", key: "revenue_src", class: "text-left align-text-top", sortable: true },
        { label: "User Source", key: "user_source", class: "text-left align-text-top", sortable: true },
        { label: "payment G/w", key: "pay_gw_id", class: "text-left align-text-top", sortable: true },
        { label: "Tax", key: "pay_tax", class: "text-left align-text-top", sortable: true },
        { label: "Billing details id", key: "pay_admin_bill_id", class: "text-left align-text-top", sortable: true },
        { label: "Params", key: "pay_params", class: "text-left align-text-top ", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left align-text-top", sortable: true }
      ],
      revenueSrcList: [
        "B2C",
        "B2B",
        "B2B2C",
        "B2B2A" // Affilate Codes Added by Counselors
      ],
      payTransactionsObjList: [],
      payTransactionsEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      delObj: null,
      propSelectedTransaction: null,
      payPlan: [],
      countFailed: 0,
      countSuccess: 0,
      captureCount: 0,
      initiatedNotPaid: 0,
      revenue: 0,
      planIdList: [],
      transactionStatusList: [],
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        },
        planId: "ALL",
        pay_status_code: "ALL",
        revenue_src: "ALL",
        search_param: "",
        downloadCsv: false
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    },
    SubscriptionPlanAmount () {
      return this.$store.getters["SubscriptionPlanAmount/selectedPlanAmountState"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()

    if (this.$route.params.student_id) {
      this.whereFilter.search_param = this.$route.params.student_id
    }

    await this.getFiltersFromCache()
    this.payTransactionsList()
    this.payTransactions_list_counts()
    this.payTransactionsDistinctTransactionStatusList()
    this.getPlanNames()
  },
  methods: {
    /**
     * get data from store cache by this method
     */
    async getFiltersFromCache () {
      await this.getDateFilter()
      await this.getSubscriptionPlanAmountFilter()
    },
    /**
     * goto Subscriptions List
     */
    gotoSubscriptionsList () {
      window.open("/subscription_list", "_blank")
    },
    /**
     * goto User List
     */
    gotoUserList () {
      window.open("/user_list", "_blank")
    },
    /**
     * gotoUserProfile
    */
    openUserProfile (userId) {
      window.open(`/user/${userId}`, "_blank")
    },
    /**
     * getPaymentTransactionDateWise for Date filter
     */
    async getPaymentTransactionDateWise () {
      this.cvSearchText = "Type to Search"
      this.setDateFilter()
      this.payTransactions_list_counts() // Get Counts for Header
      this.payTransactionsList()
    },
    /**
     * serachFromBackend
     */
    async serachFromBackend () {
      if (this.whereFilter.search_param.length <= 3) {
        return
      }
      await this.payTransactionsList()
      await this.payTransactions_list_counts()
    },
    /**
     * getPlanNames
     */
    async getPlanNames () {
      try {
        const subsPlanListResp = await SubscriptionPlans.subscriptionPlanList(this)
        if (subsPlanListResp.resp_status) {
          this.planIdList = subsPlanListResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Error in getPlanNames ", err)
      }
    },
    /**
     * openInvoice
     */
    async openInvoice (url) {
      Utility.openUrlInNewTab(url)
    },
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
     */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter.dateRange = { ...storeDate.dateRange }
      }
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * set filter by plan name
    */
    async setSubscriptionNameAndStatusFilter () {
      this.payTransactionsList()
      this.payTransactions_list_counts()
      await this.$store.commit("SubscriptionPlanAmount/addPlanAmount", this.whereFilter.planId)
    },
    /**
     * Get Subscription Plan Amount
     */
    async getSubscriptionPlanAmountFilter () {
      let storeData = await this.SubscriptionPlanAmount
      if (storeData) {
        this.whereFilter.planId = storeData
      }
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")

      document.body.appendChild(dummy)
      // Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
      console.log("Msg Copy")

      // this.snackbar = true;
      // this.err_msg = 'Copied';
    },
    /**
     * backendCsvDownload
     */
    async backendCsvDownload () {
      this.whereFilter.downloadCsv = true
      this.payTransactionsList(false, true)
    },
    /**
     * payTransactionsList
     */
    async payTransactionsList () {
      try {
        let subPlanAmountListResp = await PayTransactionss.payTransactionsList(this, this.whereFilter)
        if (subPlanAmountListResp.resp_status) {
          if (this.whereFilter.downloadCsv) {
            this.whereFilter.downloadCsv = false
            window.open(subPlanAmountListResp.resp_csv_file_url)
            return
          }
          this.payTransactionsObjList = subPlanAmountListResp.resp_data.data
          this.totalRows = subPlanAmountListResp.resp_data.count
        }
        else {
          this.payTransactionsObjList = []
          this.toastMsg = subPlanAmountListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at payTransactionsList() and Exception:", err.message)
      }
    },
    /**
     * payTransactionsAdd
     */
    payTransactionsAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/payTransactions_add")
        }
        else {
          this.showModelPayTransactionsAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at payTransactionsAdd() and Exception:", err.message)
      }
    },
    /**
     * payTransactionsEdit
     */
    payTransactionsEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("../payTransactions_edit/" + this.payTransactionsEditObj.pay_tid)
        }
        else {
          this.payTransactionsEditObj = item
          this.showModelPayTransactionsEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at payTransactionsEdit() and Exception:", err.message)
      }
    },
    /**
     * showPayTransactionsDeleteDialog
     */
    showPayTransactionsDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelPayTransactionsDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showPayTransactionsDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * payTransactionsDelete
     */
    async payTransactionsDelete () {
      try {
        let payTransactionsDelResp = await PayTransactionss.payTransactionsDelete(this, this.delObj.pay_tid)
        await ApiResponse.responseMessageDisplay(this, payTransactionsDelResp)
        if (payTransactionsDelResp && !payTransactionsDelResp) {
          this.showModelPayTransactionsDelete = false
          return false
        }
        let index = this.payTransactionsObjList.indexOf(this.delObj)
        this.payTransactionsObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelPayTransactionsDelete = false
      }
      catch (err) {
        console.error("Exception occurred at payTransactionsDelete() and Exception:", err.message)
      }
    },
    /**
     * closePayTransactionsAddModal
     */
    closePayTransactionsAddModal (payTransactionsAddData) {
      try {
        if (payTransactionsAddData) {
          if (this.payTransactionsObjList && this.payTransactionsObjList.length >= 1) {
            let payObjLength = this.payTransactionsObjList.length
            let lastId = this.payTransactionsObjList[payObjLength - 1]["id"]
            payTransactionsAddData.id = lastId + 1
          }
          else {
            this.payTransactionsObjList = []
            payTransactionsAddData.id = 11111
          }
          // payTransactionsAddData.pay_tid = `pay${payTransactionsAddData.id}`
          payTransactionsAddData.created_on = moment()
          this.payTransactionsObjList.unshift(payTransactionsAddData)
          this.totalRows = this.totalRows + 1
        }
        this.showModelPayTransactionsAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closepayTransactionsAddModal() and Exception:", err.message)
      }
    },
    /**
     * closePayTransactionsEditModal
     */
    closePayTransactionsEditModal () {
      try {
        this.showModelPayTransactionsEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closePayTransactionsEditModal() and Exception:", err.message)
      }
    },
    /**
     * payTransactions_list_counts
     */
    async payTransactions_list_counts () {
      try {
        let transactionAnalyticsResp = await analyticsPayTransactions.payTransactions_list_counts(this, this.whereFilter)
        if (transactionAnalyticsResp.resp_status) {
          if (transactionAnalyticsResp.resp_data.data[0].total_revenue > 0) {
            this.revenue = transactionAnalyticsResp.resp_data.data[0].total_revenue
          }
          if (transactionAnalyticsResp.resp_data.data[0].total_count > 0) {
            this.countSuccess = transactionAnalyticsResp.resp_data.data[0].total_count
          }
        }
        else {
          this.toastMsg = transactionAnalyticsResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at analytics_subs_planwise_usercount() and Exception:", err.message)
      }
    },
    /**
     * payTransactionsDistinctTransactionStatusList
     */
    async payTransactionsDistinctTransactionStatusList () {
      try {
        let payTransStatusCode = await PayTransactionss.payTransactionsDistinctTransactionStatusList(this)
        if (payTransStatusCode.resp_status) {
          this.transactionStatusList = payTransStatusCode.resp_data.data
        }
        else {
          this.toastMsg = payTransStatusCode.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at payTransactionsDistinctTransactionStatusList() and Exception:", err.message)
      }
    }
  }
}
</script>
